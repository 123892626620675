const coinGeckoWidget = document.querySelector("gecko-coin-price-static-headline-widget");
const shadowRootStyle = coinGeckoWidget?.shadowRoot?.querySelector("style");
if( shadowRootStyle ) {
  shadowRootStyle.innerHTML = shadowRootStyle?.innerHTML + `
  .gecko-divider, 
  .gecko-footer .gecko-text-light { 
    display: none; 
  } 
  
  .gecko-widget { 
    display: flex; 
    flex-direction: row-reverse;
  } 
  
  .gecko-widget:not(.gecko-outline) { 
    padding: 0 16px 0 0; 
  } 
  
  .gecko-footer { 
    margin-bottom: 0; 
    flex-direction: 
    row-reverse; 
  gap: 10px; } 
  
  .gecko-items-wrapper { 
    padding-bottom: 0px; 
  } 
  
  .gecko-static-group .gecko-static-item { 
    padding-left: 30px; 
  } 
  
  .gecko-static-item { 
    display: grid; 
    grid-template-rows: auto auto; 
    grid-template-columns: auto auto;  
  } 
  
  .gecko-static-item div:first-child { 
    grid-row: span 2; 
    margin-right: 30px; 
  } 
  
  .gecko-price { 
    font-size: 16px; 
    line-height: 20px; 
  }`;
}

const mod = {};
mod.core = () => {
  const menu = document.querySelector(".main-header__nav"),
    hamburger = document.querySelector(".hamburger"),
    header = document.querySelector(".main-header__main");
  // banner = document.querySelector(".main-header__top");
  // let bannerHeight = banner.clientHeight;

  // Open Menu
  hamburger.addEventListener("click", () => {
    menu.classList.toggle("open");
    hamburger.classList.toggle("open");
    document.body.classList.toggle("overflow");
  });

  // Close Menu
  const menuItems = document.querySelectorAll(".main-header__nav a");
  menuItems.forEach((item) => {
    item.addEventListener("click", () => {
      menu.classList.remove("open");
      hamburger.classList.remove("open");
      document.body.classList.remove("overflow");
    });
  });

  // // Get banner height
  // window.addEventListener("resize", () => {
  //   bannerHeight = banner.clientHeight;
  // });

  // // Sticky
  // window.addEventListener("scroll", () => {
  //   if (window.scrollY > bannerHeight) {
  //     header.classList.add("sticky");
  //   } else {
  //     header.classList.remove("sticky");
  //   }
  // });

  const del = (el) => {
    if (!el || !el.parentNode) return;
    el.parentNode.removeChild(el);
  };

  const each = (arr, fn) => {
    let i = 0,
      l = arr.length;
    for (i; i < l; i++) {
      fn(i, arr[i]);
    }
  };

  const render = (relEl, tpl, cfg = {}) => {
    if (!relEl) return;
    let child;

    if (tpl.nodeName === "TEMPLATE") {
      child = document.importNode(tpl.content, true);
    } else if (typeof tpl === "string") {
      const range = document.createRange();
      range.selectNode(relEl);
      child = range.createContextualFragment(tpl);
    } else {
      child = tpl;
    }

    relEl.appendChild(child);
    child = null;
    tpl = null;
    cfg = null;
    return relEl.lastElementChild;
  };

  let video_lang = document.body.querySelector('select[name="load_video"]'),
    tpl = {
      0: video_lang.nextElementSibling.firstChild,
    },
    video_nav = video_lang.previousElementSibling;
  video_nav &&
    video_nav.addEventListener("click", (e) => {
      e.target.classList.toggle("open");
    });

  video_nav.children.length &&
    each(video_nav.children, (key, val) => {
      val.addEventListener("click", (e) => {
        e.target.parentNode.classList.toggle("open");
        e.target.parentNode.dataset.name = val.textContent;
        video_lang.selectedIndex = key;
        renderVideo(video_lang);
      });
    });

  video_lang &&
    video_lang.addEventListener("change", (e) => {
      renderVideo(e.target);
    });

  const renderVideo = (el) => {
    del(el.nextElementSibling.firstChild);
    !tpl[el.selectedIndex]
      ? render(
          el.nextElementSibling,
          `<iframe id="vimeoVideo" loading="lazy" src="${el.value}" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%" title="Introducing BAFL"></iframe>`
        )
      : el.nextElementSibling.appendChild(tpl[el.selectedIndex]);
  };
  renderVideo(video_lang);

  // const video = document.getElementById("vimeoVideo");
  // const observer = new IntersectionObserver(
  //   (entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         const src = video.src;
  //         if (src.includes("autoplay=0")) {
  //           video.src = src.replace("autoplay=0", "autoplay=1");
  //         }
  //         observer.unobserve(video);
  //       }
  //     });
  //   },
  //   { threshold: 0.5 }
  // );
  // observer.observe(video);

  // const cards = document.querySelectorAll(
  //   ".strategy > .cards-container > .card"
  // );

  // const leftButton = document.getElementById("left-button-strategy");
  // const rightButton = document.getElementById("right-button-strategy");

  // const tabs = document.querySelectorAll(".tabs-container > h2");

  // const toggleCards = (tab) => {
  //   rightButton.disabled = !rightButton.disabled;
  //   leftButton.disabled = !leftButton.disabled;

  //   if (tab && !tab.classList.contains("selected")) {
  //     tabs.forEach((t) => {
  //       t.classList.toggle("selected");
  //     });
  //   }

  //   cards.forEach((card) => {
  //     if (card.classList.contains("hidden")) {
  //       card.classList.remove("hidden");
  //     } else {
  //       card.classList.add("hidden");
  //     }
  //   });
  // };

  // rightButton &&
  //   rightButton.addEventListener("click", () => {
  //     toggleCards(tabs[1]);
  //   });

  // leftButton &&
  //   leftButton.addEventListener("click", () => {
  //     toggleCards(tabs[0]);
  //   });

  // tabs[0] &&
  //   tabs[0].addEventListener("click", () => {
  //     toggleCards(tabs[0]);
  //   });

  // tabs[1] &&
  //   tabs[1].addEventListener("click", () => {
  //     toggleCards(tabs[1]);
  //   });

  // const links = document.querySelectorAll(".main-header__nav > ul > li > a");
  // links.forEach((link) => {
  //   link.addEventListener("click", (e) => {
  //     if (link.hash && document.querySelector(link.hash)) {
  //       e.preventDefault();
  //       document
  //         .querySelector(link.hash)
  //         .scrollIntoView({ behavior: "smooth" });
  //     }
  //   });
  // });
};

document.readyState === "loading"
  ? document.addEventListener("DOMContentLoaded", mod.core)
  : mod.core();
